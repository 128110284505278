<template>
  <div>

    <!-- Filter Section -->
    <FilterReport title="Filters" :baseReportUrl="baseReportUrl" @clicked-filter="getReport" />

    <div class="d-flex justify-content-end mt-1 mb-1">

      <b-button variant="outline-primary" @click="print">
        <feather-icon icon="FileIcon" />
        {{ $t('globalActions.print') }}
      </b-button>

      <b-button-group class="ml-1">
        <b-dropdown right variant="outline-primary">

          <template #button-content>
            <feather-icon icon="DownloadIcon"/>
            {{ $t('globalActions.export') }}
          </template>

          <b-dropdown-item-button @click="exportToPDF">PDF</b-dropdown-item-button>
          <b-dropdown-item-button @click="exportToExcel">Excel</b-dropdown-item-button>

        </b-dropdown>
      </b-button-group>

    </div>

    <!-- Table Container Card -->
    <b-card no-body id="pdfDom">

      <b-card-body class="text-center">
        <b-card-title>{{ companyName }}</b-card-title>
        <b-card-sub-title>
          <div>{{ $t('apps.reportProfitLoss.singular.purchaseInvoiceDetailReport') }}</div>
          <div class="mt-sm-1">{{ periodReportText }}</div>
          <div v-if="officeReportText" class="mt-sm-1">{{ officeReportText }}</div>
        </b-card-sub-title>
      </b-card-body>

      <b-table-simple id="profit-loss-table">
        <b-thead>
          <b-tr>
            <b-th v-for="column in tableColumns" :key="column.key" :class="column.thClass" >{{ column.label }}</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(prop, index) in itemLists" :key="prop.coa">
            <b-td
              v-for="(column, idx) in tableColumns"
              :key="`${column.key}-${index}`"
              :class="stylingCOA(prop.rowNumber, column.tdClass, idx)">
              {{ prop[column.key] }}
            </b-td>
          </b-tr>
        </b-tbody>
        <b-tfoot>
          <b-tr>
            <!-- <b-td class="text-right font-weight-bolder font-italic">Total</b-td> -->
            <!-- <b-td class="text-right font-weight-bolder font-italic">{{ total.balance }}</b-td> -->
          </b-tr>
        </b-tfoot>
      </b-table-simple>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BButtonGroup,
  BButton,
  BDropdown,
  BDropdownItemButton,
  BTableSimple,
  BThead,
  BTh,
  BTr,
  BTd,
  BTbody,
  BTfoot
} from 'bootstrap-vue'
import FilterReport from '@/components/reports/FilterReport.vue'

import { formatCurrency } from '@/utils/formatter'
import useListTable from '@/comp-functions/useListTable'
import useFilterReport from '@/comp-functions/useFilterReport'
// import router from '@/router'

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BButtonGroup,
    BButton,
    BDropdown,
    BDropdownItemButton,
    BTableSimple,
    BThead,
    BTh,
    BTr,
    BTd,
    BTbody,
    BTfoot,
    FilterReport
  },
  computed: {
    companyName () {
      return this.$store.state.user.currentCompany.name
    }
  },
  setup () {
    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists
    } = useListTable({ url: false })

    // export format
    const formats = ['xlsx', 'csv', 'txt']

    // Table Handlers
    const tableColumns = [
      { key: 'coa', label: 'Account' },
      { key: 'balance_view', label: 'Amount', thClass:'text-right', tdClass: 'text-right' }
    ]

    const setPadding = (row) => {
      return `padding-left: ${(row.account_level - 1) * 30}px;`
    }

    const stylingCOA = (rowNumber, tdClass, idx) => {
      return {
        [tdClass]: true,
        'text-right pr-0': idx == '0' && rowNumber == '99',
        'header-category-account': rowNumber == '0',
        'child-account': rowNumber == '2',
        'total-category-account': rowNumber == '1' || rowNumber == '3',
        'total-top-category-account': rowNumber == '99'
      }
    }

    return {
      stylingCOA,
      formatCurrency,
      formats,
      tableColumns,
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      isSortDirDesc,
      refListTable,
      statusFilter,
      setPadding,
      ...useFilterReport({
        baseUrl: 'reports/profit-loss',
        fetchLists
      })
    }
  },
  methods: {
    print () {
      window.print()
    },
    exportToPDF () {
      this.$nextTick(() => this.getPdf())
    },
    exportToExcel () {
      import('@/vendors/Export2Excel').then(excel => {
        const list = this.itemLists

        // add title
        const headerTitle = this.tableColumns.map(column => column.label)
        // only take indexData from columnDef,
        const headerVal = this.tableColumns.map(column => column.key)
        const data = this.formatJson(headerVal, list)
        excel.export_json_to_excel({
          multiHeader: [
            [this.$store.state.user.currentCompany.name, '', '', '', '', '', '', '', '', '', '', '', ''],
            [`${this.$t('apps.reportProfitLoss.singular.purchaseInvoiceDetailReport')}`, '', '', '', '', '', '', '', '', '', '', '', ''],
            [this.periodReportText, '', '', '', '', '', '', '', '', '', '', '', ''],
            ['', '', '', '', '', '', '', '', '', '', '', '', '']
          ],
          header: headerTitle,
          data,
          merges: [
            { s: { c: 0, r: 0 }, e: { c: 12, r: 0 } },
            { s: { c: 0, r: 1 }, e: { c: 12, r: 1 } },
            { s: { c: 0, r: 2 }, e: { c: 12, r: 2 } },
            { s: { c: 0, r: 3 }, e: { c: 12, r: 3 } }
          ],
          filename: `${this.$t('apps.reportProfitLoss.singular.purchaseInvoiceDetailReport')} ${this.periodReportText}`,
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .dropdown-menu .dropdown-item {
    width: 100%;
  }

  .header-category-account {
    font-weight: 600;
    font-size: 1rem;
  }

  .child-account {
    padding-left: 48px !important;
    font-size: 0.9rem;
  }

  .total-category-account {
    font-weight: 600;
    font-size: 0.9rem;
    padding-left: 40px !important;
  }

  .total-top-category-account {
    font-weight: 600;
    font-size: 1rem;
    font-style: italic;
  }

  #profit-loss-table {
    width: inherit;
    margin: 0 160px 20px 160px;
    padding: 20px 0;

    thead {
      th {
        border-top: 2px solid #ebe9f1;
      }
      th:first-child {
        border-left: 2px solid #ebe9f1;
        border-radius: 4px;
      }

      th:last-child {
        border-right: 2px solid #ebe9f1;
        border-radius: 4px;
      }
    }

    td:first-child {
      border-left: 2px solid #ebe9f1;
    }
    td:last-child {
      border-right: 2px solid #ebe9f1;
    }

    tbody {
      tr:last-child {
        border-bottom: 2px solid #ebe9f1;
      }
    }

  }

  @media print {
    #app {
      * {
        visibility: hidden;
        margin: 0 !important;
      }

      #pdfDom,
      #pdfDom * {
        visibility: visible;
      }

      #pdfDom {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        margin-top: 100px;
      }

      #pdfDom td, #pdfDom th {
        padding: 0.72rem 1rem;
      }
    }
  }

  @page {
    size: auto;
  }
</style>
